/*eslint-disable*/
import React from "react";
// nodejs library that concatenates classes
import classNames from "classnames";
// react components used to create a google map
import {
  withScriptjs,
  withGoogleMap,
  GoogleMap,
  Marker
} from "react-google-maps";
// @material-ui/core components
import { makeStyles } from "@material-ui/core/styles";
import List from "@material-ui/core/List";
import ListItem from "@material-ui/core/ListItem";
// @material-ui/icons
import Favorite from "@material-ui/icons/Favorite";
import PinDrop from "@material-ui/icons/PinDrop";
import Phone from "@material-ui/icons/Phone";
import BusinessCenter from "@material-ui/icons/BusinessCenter";
// core components
import Header from "components/Header/Header.js";
import HeaderLinks from "components/Header/HeaderLinks.js";
import GridContainer from "components/Grid/GridContainer.js";
import GridItem from "components/Grid/GridItem.js";
import InfoArea from "components/InfoArea/InfoArea.js";
import CustomInput from "components/CustomInput/CustomInput.js";
import Button from "components/CustomButtons/Button.js";
import Footer from "components/Footer/Footer.js";
import Layout from "views/Layout/Layout.js";
import dg1 from "assets/img/contactus.jpg";
import {Helmet} from "react-helmet";
import contactUsStyle from "assets/jss/material-kit-pro-react/views/contactUsStyle.js";
import Parallax from "components/Parallax/Parallax.js";
const CustomSkinMap = withScriptjs(
  withGoogleMap(() => (
    <GoogleMap
      defaultZoom={14}
      defaultCenter={{ lat: 44.43353, lng: 26.093928 }}
      defaultOptions={{
        scrollwheel: false,
        zoomControl: true,
        styles: [
          {
            featureType: "water",
            stylers: [
              { saturation: 43 },
              { lightness: -11 },
              { hue: "#0088ff" }
            ]
          },
          {
            featureType: "road",
            elementType: "geometry.fill",
            stylers: [
              { hue: "#ff0000" },
              { saturation: -100 },
              { lightness: 99 }
            ]
          },
          {
            featureType: "road",
            elementType: "geometry.stroke",
            stylers: [{ color: "#808080" }, { lightness: 54 }]
          },
          {
            featureType: "landscape.man_made",
            elementType: "geometry.fill",
            stylers: [{ color: "#ece2d9" }]
          },
          {
            featureType: "poi.park",
            elementType: "geometry.fill",
            stylers: [{ color: "#ccdca1" }]
          },
          {
            featureType: "road",
            elementType: "labels.text.fill",
            stylers: [{ color: "#767676" }]
          },
          {
            featureType: "road",
            elementType: "labels.text.stroke",
            stylers: [{ color: "#ffffff" }]
          },
          { featureType: "poi", stylers: [{ visibility: "off" }] },
          {
            featureType: "landscape.natural",
            elementType: "geometry.fill",
            stylers: [{ visibility: "on" }, { color: "#b8cb93" }]
          },
          { featureType: "poi.park", stylers: [{ visibility: "on" }] },
          {
            featureType: "poi.sports_complex",
            stylers: [{ visibility: "on" }]
          },
          { featureType: "poi.medical", stylers: [{ visibility: "on" }] },
          {
            featureType: "poi.business",
            stylers: [{ visibility: "simplified" }]
          }
        ]
      }}
    >
      <Marker position={{ lat: 44.43353, lng: 26.093928 }} />
    </GoogleMap>
  ))
);

const useStyles = makeStyles(contactUsStyle);

export default function ContactUsPage() {
  React.useEffect(() => {
    window.scrollTo(0, 0);
    document.body.scrollTop = 0;
  });
  const classes = useStyles();
  
  const schemaOrgJSONLD = [
    {
      '@context': 'http://schema.org',
      "@type": "WebPage",
      'name':'BUSLOC ContactUs page',
      'description':'Contact BUSLOC for all your queries related to BUSLOC platform',
      'url':'www.busloc.io/contactus',
      'keywords':'BUSLOC, contact us, Queries',
      'og:description':'Contact BUSLOC for all your queries related to BUSLOC platform',
      'og:title':'Contact BUSLOC for your queries, we will revert back'
      
    }
  ];
  return (
    <Layout>
  
    <Helmet>
       
       <title>Contact BUSLOC for your queries, we will revert back</title>
       <meta name="description" content="BUSLOC contact us helps you in sending all your queries related to BUSLOC platform" />
       <meta name="keywords" content="BUSLOC, contact us, Queries" />
       <script type="application/ld+json">
   {JSON.stringify(schemaOrgJSONLD)}
 </script>
   {/*    meta={[
         { name: 'description', content: 'BUSLOC contact us helps you in sending all your queries related to BUSLOC platform'},
         { name: 'keywords', content: 'BUSLOC, schoolbus, Text messages, Live Tracking, Free ' },
         { property: 'og:type', content: 'website' }
   ]} */}

     
     </Helmet>
     <Parallax image={require("assets/img/contactus.svg")}  small>
        <div className={classes.container}>
          <GridContainer justify="center">
            <GridItem xs={12} sm={12} md={8} className={classes.textCenter}>
             
            </GridItem>
          </GridContainer>
        </div>
      </Parallax>
    
       <div className={classNames(classes.main, classes.mainRaised)}>
        <div className={classes.contactContent}>
          <div className={classes.container}>
            
            <GridContainer>
                <GridItem sm={3} md={3}></GridItem>
                <GridItem md={6} sm={6}>
                <h1 className={classes.title} style={{fontSize:"40px"}}>Drop us a message...</h1>
                  <p>
                  Contact us on any issue related to BUSLOC, We will get in touch with you as soon as possible
                    <br />
                    <br />
                  </p>
                  <form id="scheduleademo" method="POST" 
       action="https://formspree.io/xzbkppka">
                    <CustomInput
                      labelText="Your Name" name="name"
                      id="name"
                      formControlProps={{
                        fullWidth: true
                      }}
                      inputProps={{
                        name:"name"
                       }}
                    />
                    <CustomInput
                      labelText="Email address" name="emailaddress"
                      id="emailaddress"
                      formControlProps={{
                        fullWidth: true
                      }}
                      inputProps={{
                        name:"emailaddress",
                        required:true
                       }}
                    />
                    <CustomInput
                      labelText="Phone" name="phone"
                      id="phone"
                      formControlProps={{
                        fullWidth: true
                      }}
                    />
                    <CustomInput
                      labelText="Your message" name="message"
                      id="message"
                      formControlProps={{
                        fullWidth: true
                      }}
                      inputProps={{
                        multiline: true,
                        rows: 6,
                        name:"message"
                      }}
                    />
                     <input type="hidden" name="_subject" value="contactus" />
           <Button type="submit" value="Send" round color="info" className={classes.pullRight} style={{fontWeight:600}}>
           Contact us
           </Button>
                 
                   {/*   <input type="hidden" name="_subject" value="contactus" />
                    <div className={classes.textCenter}>
                      <Button color="info" round type="submit" value="Send">
                        Contact us
                      </Button>
                    </div> */}
                  </form>
                </GridItem>
                
              </GridContainer>
          </div>
        </div>
      </div>
          
     
     
   
    </Layout>
  );
}
